<!-- 角色信息页面（新增、编辑、详情） -->
<template>
  <div>
    <a-modal
      :title="isCreate == 0 ? '新增角色' : '编辑角色'"
      width="40%"
      okText="确定"
      cancelText="取消"
      v-model:visible.sync="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div>
        <a-form ref="roleForm" :model="roleInfo" :rules="rules">
          <a-form-item
            label="角色名称:"
            name="name"
            :labelCol="formLayout2X2.labelCol"
            :wrapperCol="formLayout2X2.wrapperCol"
          >
            <a-input
              v-model:value="roleInfo.name"
              type="text"
              placeholder="请输入角色名称"
            >
              <template v-slot:prefix>
                <EditOutlined style="color: rgba(0, 0, 0, 0.25)" />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item
            label="角色编码:"
            name="code"
            :labelCol="formLayout2X2.labelCol"
            :wrapperCol="formLayout2X2.wrapperCol"
          >
            <a-input
              v-model:value="roleInfo.code"
              addon-before="ROLE_"
              type="text"
              placeholder="请输入角色编码"
            >
              <template v-slot:prefix>
                <EditOutlined style="color: rgba(0, 0, 0, 0.25)" />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item
            label="备注说明:"
            name="memo"
            :labelCol="formLayout2X2.labelCol"
            :wrapperCol="formLayout2X2.wrapperCol"
          >
            <a-textarea
              v-model:value="roleInfo.memo"
              placeholder="填写备注说明"
              :rows="4"
            />
          </a-form-item>
        </a-form>
      </div>
      <template #closeIcon
        ><SvgIcon iconName="sclose" className="svg201"
      /></template>
      <template #footer>
        <a-button v-show="isCreate < 2" @click="handleCancel">取消</a-button>
        <a-button v-show="isCreate < 2" type="primary" @click="handleOk"
          >确定</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import { EditOutlined } from "@ant-design/icons-vue";
import SvgIcon from "@/components/Svgicon";
import {
  ref,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  defineComponent,
  watch,
} from "vue";
import { message } from "ant-design-vue";
import { saveRole } from "@/api/permis/role";
export default defineComponent({
  name: "RoleForm",
  components: {
    EditOutlined,
    SvgIcon,
  },
  // 接受父组件传递的值
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
    },
    isCreate: {
      type: Number,
      default: 0,
    },
    searchParam: {
      type: Object,
      default: () => {},
    },
    editData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, context) {
    const roleForm = ref();
    const state = reactive({
      visible: false,
      confirmLoading: false,
      roleInfo: {
        id: null,
        name: "",
        code: "",
        memo: "",
      },
      formLayout2X2: {
        labelCol: {
          span: 4,
        },
        wrapperCol: {
          span: 18,
        },
      },
    });
    const rules = {
      name: [{ required: true, message: "角色名称不能为空", trigger: "blur" }],
      code: [
        { required: true, message: "角色编码不能为空！", trigger: "blur" },
      ],
    };
    onBeforeMount(() => {});
    onMounted(() => {});
    const handleOk = () => {
      roleForm.value
        .validate()
        .then((success) => {
          if (success) {
            state.confirmLoading = true;
            saveRole(state.roleInfo).then((resp) => {
              if (resp) {
                roleForm.value.resetFields();
                setTimeout(() => {
                  context.emit("saveCallBack", resp.code);
                  context.emit("handleModalVisible", false);
                  state.confirmLoading = false;
                }, 1000);
              }
            });
          } else {
            message.error("角色添加失败！！！");
            return false;
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
    const handleCancel = () => {
      context.emit("handleModalVisible", false);
    };
    const refData = toRefs(state);
    watch(
      () => [props.editData, props.modalVisible],
      ([nv1, nv2]) => {
        state.visible = nv2;
        state.roleInfo = nv1;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    return {
      ...refData,
      rules,
      handleOk,
      handleCancel,
      roleForm,
    };
  },
});
</script>
<style lang='scss' scoped>
</style>