<!-- 角色信息维护 -->
<template>
  <div>
    <div>
      <div class="tool">
        <div>
          <a-input-search
            v-model:value="keyword"
            placeholder="请输入关键词"
            enter-button
            @search="onSearch"
            @change.stop="onSearchChange"
          />
        </div>
        <div>
          <a-button type="primary" @click.stop="doAddRole">
            <PlusOutlined />添加角色
          </a-button>
        </div>
      </div>
    </div>
    <div style="margin-top: 6px">
      <a-table
        :rowKey="(record) => record.id"
        :pagination="false"
        class="ant-table-striped"
        size="middle"
        :loading="loading"
        bordered
        :columns="columns"
        :data-source="roleList"
        :rowClassName="
          (record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
      >
        <template #action="{ record }">
          <span>
            <a-button type="link" size="small" @click="doDeleteRole(record)">
              <SvgIcon iconName="delete_red" className="svg15" />
              <span style="color: red">删除</span>
            </a-button>
            <a-divider type="vertical" style="border-color: #7cb305" dashed />
            <a-button type="link" size="small" @click="doEditRole(record)">
              <EditOutlined />编辑
            </a-button>
          </span>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="pagination.pageIndex"
          :pageSize="pagination.pageSize"
          :total="pagination.total"
          :page-size-options="pagination.pageSizeOptions"
          :showTotal="(total) => `共 ${total} 条记录`"
          show-size-changer
          @change="onChangePage"
          @showSizeChange="onShowSizeChange"
        >
          <template #buildOptionText="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <div>
      <RoleForm
        :modalVisible="modalVisible"
        :isCreate="isCreate"
        :searchParam="searchParam"
        :editData="editData"
        @handleModalVisible="handleModalVisible"
        @saveCallBack="saveCallBack"
      />
    </div>
  </div>
</template>
<script>
import {
  PlusOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { reactive, toRefs, onMounted, defineComponent, createVNode } from "vue";
import { getRoleListPage, deleteRole } from "@/api/permis/role";
import moment from "moment";
import RoleForm from "./components/RoleForm.vue";
import { message, Modal } from "ant-design-vue";
import SvgIcon from "@/components/Svgicon";
export default defineComponent({
  name: "Role",
  components: {
    PlusOutlined,
    EditOutlined,
    RoleForm,
    SvgIcon,
  },
  setup() {
    const state = reactive({
      keyword: "",
      loading: false,
      isCreate: 0, //0新增  1编辑  2详情
      editData: {
        id: "",
        name: "",
        code: "",
        memo: "",
      },
      modalVisible: false,
      searchParam: {
        searchValue: "",
        pageIndex: 1,
        pageSize: 10,
      },
      roleList: [],
      pagination: {
        pageIndex: 1, // 默认显示第1页
        pageSize: 10, // 默认每页显示数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        total: 0, //总条数
      },
    });
    const columns = [
      {
        title: "角色名称",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "角色编码",
        dataIndex: "code",
        key: "code",
      },

      {
        title: "创建时间",
        dataIndex: "createDate",
        key: "createDate",
        customRender: ({ text }) => {
          return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "";
        },
      },
      {
        title: "备注",
        dataIndex: "memo",
        key: "memo",
      },
      {
        title: "操作",
        align: "center",
        key: "action",
        fixed: "right",
        slots: {
          customRender: "action",
        },
      },
    ];
    onMounted(() => {
      if (state.searchValue) {
        state.searchParam.searchValue = state.keyword;
      }
      initRoleList(state.searchParam);
    });
    const initRoleList = (searchParam) => {
      state.loading = true;
      getRoleListPage(searchParam).then((resp) => {
        if (resp) {
          state.roleList = resp.obj.list;
          state.pagination.total = resp.obj.pagination.total;
          state.loading = false;
        }
      });
    };
    const onSearch = () => {
      state.searchParam = {
        searchValue: state.keyword,
        pageIndex: 1,
        pageSize: 10,
      };
      initRoleList(state.searchParam);
    };
    const onSearchChange = () => {
      state.searchParam.searchValue = state.keyword;
    };
    const onChangePage = (page, pageSize) => {
      if (state.keyword) {
        state.searchParam.searchValue = state.keyword;
      }
      state.pagination.pageIndex = page;
      state.searchParam.pageIndex = page;
      state.searchParam.pageSize = pageSize;
      initRoleList(state.searchParam);
    };
    const onShowSizeChange = (page, pageSize) => {
      if (state.keyword) {
        state.searchParam.searchValue = state.keyword;
      }
      state.pagination.pageSize = pageSize;
      state.pagination.pageIndex = 1;
      state.searchParam.pageIndex = 1;
      state.searchParam.pageSize = pageSize;
      initRoleList(state.searchParam);
    };
    const handleModalVisible = (flag) => {
      state.modalVisible = flag;
    };
    const doDeleteRole = (record) => {
      Modal.confirm({
        title: "温馨提示",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确认",
        cancelText: "取消",
        content: createVNode(
          "div",
          { style: "color:red;" },
          "此操作讲永久删除角色【" + record.rolename + "】，是否继续？"
        ),
        onOk() {
          deleteRole(record.id).then((resp) => {
            if (resp) {
              initRoleList(state.searchParam);
            }
          });
        },
        onCancel() {
          message.info("已取消删除角色操作！！！");
        },
      });
    };
    const doAddRole = () => {
      handleModalVisible(true);
      state.isCreate = 0;
    };
    const doEditRole = (record) => {
      handleModalVisible(true);
      state.editData = {
        id: record.id,
        name: record.name,
        code: record.code,
        memo: record.memo,
      };
      state.isCreate = 1;
    };
    const saveCallBack = (code) => {
      if (code == 200) {
        initRoleList(state.searchParam);
      }
    };
    const refData = toRefs(state);
    return {
      ...refData,
      columns,
      initRoleList,
      onSearch,
      onSearchChange,
      onChangePage,
      onShowSizeChange,
      handleModalVisible,
      doDeleteRole,
      doAddRole,
      doEditRole,
      saveCallBack,
    };
  },
});
</script>
<style lang='scss' scoped>
.tool {
  display: flex;
  justify-content: space-between;
}

.ant-input-search {
  width: 300px;
  margin-right: 5px;
}
.pagination {
  margin: 6px 0px;
  padding: 6px;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(183, 217, 241, 0.5);
}
</style>